import FilterInputItems from "../shared/Filter/FilterInputItems";
import {tableContentType} from "../shared/table/table_utils";

export const designationConstants = {
  filters: [
    FilterInputItems.id,
    FilterInputItems.title,
    FilterInputItems.status,
  ],
  listAttributes: [
    {
      title: 'ID',
      field: 'id',
      type: tableContentType.ID,
    },
    {
      title: 'Title',
      field: 'title',
      type: tableContentType.TEXT,
    },
    {
      title: 'Status',
      field: 'status',
      type: tableContentType.STATUS,
    },
    {
      title: 'Action',
      field: 'action',
      type: tableContentType.ACTION,
    },
  ],
  detailsAttributes: [
    {
      title: 'ID',
      field: 'id',
      type: tableContentType.TEXT,
    },
    {
      title: 'Title',
      field: 'title',
      type: tableContentType.TEXT,
    },
    {
      title: 'Status',
      field: 'status',
      type: tableContentType.STATUS,
    },
    {
      title: 'Created At',
      field: 'created_at',
      type: tableContentType.DATE_TIME,
    },
  ]
}

