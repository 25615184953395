import React from "react";
import BlocBuilder from "../../BlocBuilder";
import SettingsBloc from "../../bloc/SettingsBloc";
import AuthBloc from "../../bloc/AuthBloc";
import {Col, DatePicker, Form, Row} from "antd";
import {Colors} from "../../css/Colors";
import {TextX} from "../shared/TextX";
import {InputText} from "../shared/FormComponent/InputText";
import {Box} from "../shared/Box";
import {ButtonX} from "../shared/ButtonX";
import {InputFields} from "../../utils/InputFields";
import CrudBloc from "../../bloc/CrudBloc";
import {MenuConfig} from "../../utils/Constants";
import {InputFieldOptions} from "../../utils/InputFieldOptions";
import {InputSelect} from "../shared/FormComponent/InputSelect";
import {IsLoading} from "../shared/IsLoading";
import {FormSkeleton} from "../shared/FormSkeleton";
import moment from "moment";
import {withRouter} from "../../withRouter";
import {createResponseSub, updateResponseSub} from "../common/CreateUtils";


class InstitutionCreatePage extends React.Component{

    formRef = React.createRef();
    constructor(props) {
        super(props);
        

        this.state = {
            name: InputFields.name,
            category_id: InputFields.category_id,
            address_line_1_house_road: InputFields.address_line_1_house_road,
            address_line_2_city: InputFields.address_line_2_city,
            address_line_3_district: InputFields.address_line_3_district,
            pharmacy_name: InputFields.pharmacy_name,

            contact_person_name: InputFields.contact_person_name,
            contact_person_mobile: InputFields.contact_person_mobile,
            contact_person_birthday: InputFields.contact_person_birthday,
            contact_person_marriage_day: InputFields.contact_person_marriage_day,
            contact_person_relationship: InputFields.contact_person_relationship,

            status: InputFields.status
        };

        if(this.props.edit){
            const {id} = this.props.params;
            CrudBloc.instance.getDetails(id, MenuConfig.institution.lower);
        }else{
            CrudBloc.instance.clearDetails(MenuConfig.institution.lower);
        }

        CrudBloc.instance.getList({status: 1, limit: 100}, MenuConfig.category)
    }

    isEdit = () => {
        return this.props.edit;
    }

    componentDidMount() {
        if(this.props.edit) {
            SettingsBloc.instance.setCurrentPageTitle("Institution Update");
            this.updateResponseSubscription = updateResponseSub(this.props.navigate,MenuConfig.institution.lower)
        } else {
            SettingsBloc.instance.setCurrentPageTitle("Create new Institution");
            this.createResponseSubscription = createResponseSub(this.props.navigate,MenuConfig.institution.lower);
        }
    }

    componentWillUnmount() {
        this.createResponseSubscription?.unsubscribe();
        this.updateResponseSubscription?.unsubscribe();
    }

    onFinish = (values) => {
        console.log('Success:', values);
        if(this.props.edit) {
            const {id} = this.props.params;
            CrudBloc.instance.update(id, values, MenuConfig.institution);
        } else {
            values[this.state.contact_person_birthday.name] = moment(values[this.state.contact_person_birthday.name]).format("YYYY-MM-DD 10:00:00");
            values[this.state.contact_person_marriage_day.name] = moment(values[this.state.contact_person_marriage_day.name]).format("YYYY-MM-DD 10:00:00");

            CrudBloc.instance.createNew(values, MenuConfig.institution);
        }
    };

    render() {
        return (
            <IsLoading name={MenuConfig.institution.lower}>
                <BlocBuilder
                    subject = {CrudBloc.instance.institution}
                    builder = {(snapshot) => {
                        console.log(snapshot.data);
                        console.log(snapshot.data ? snapshot.data[this.state.title.name] : null);
                        return   !this.props.edit ||  snapshot.data ? <Form ref={this.formRef} name="control-ref" onFinish={this.onFinish} layout="vertical" className="create-form-full">
                            <Row gutter={16}>
                                <Col span={8}>
                            <InputText values={this.state.name}
                                       value={this.props.edit && snapshot.data ? snapshot.data[this.state.name.name] : undefined}/>
                                </Col> <Col span={8}>
                            <BlocBuilder
                                subject = {CrudBloc.instance.categoryList}
                                builder = {(snapshotCategory) => {
                                    console.log(snapshotCategory.data);
                                    return  <InputSelect
                                        values={this.state.category_id}
                                        options={snapshotCategory.data ? snapshotCategory.data.list : []}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.category_id.name] : undefined}
                                    />
                                }}/>
                            </Col>
                            {/*    <Col span={8}>*/}
                            {/*<InputSelect*/}
                            {/*    values={this.state.class_id}*/}
                            {/*    options={InputFieldOptions.class}*/}
                            {/*    value={this.props.edit && snapshot.data ? snapshot.data[this.state.class_id.name] : 1}*/}
                            {/*/>*/}
                            {/*    </Col>*/}
                            </Row>

                            <Row gutter={16}>
                                <Col span={8}>

                                    <InputText
                                        values={this.state.address_line_1_house_road}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.address_line_1_house_road.name] : undefined}/>
                                </Col>  <Col span={8}>
                                <InputText
                                    values={this.state.address_line_2_city}
                                    value={this.props.edit && snapshot.data ? snapshot.data[this.state.address_line_2_city.name] : undefined}/>
                            </Col>
                                <Col span={8}>
                                    <InputText
                                        values={this.state.address_line_3_district}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.address_line_3_district.name] : undefined}/>

                                </Col>
                            </Row>



                            <Row gutter={16}>
                                <Col span={8}>

                                    <InputText
                                        values={this.state.contact_person_name}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.contact_person_name.name] : undefined}/>
                                </Col> <Col span={8}>
                                <InputText
                                    values={this.state.contact_person_mobile}
                                    value={this.props.edit && snapshot.data ? snapshot.data[this.state.contact_person_mobile.name] : undefined}/>

                            </Col>
                                <Col span={8}>
                                    <InputText
                                        values={this.state.contact_person_relationship}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.contact_person_relationship.name] : undefined}/>

                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item name={this.state.contact_person_birthday.name} label={this.state.contact_person_birthday.label} {...config}

                                               initialValue={this.isEdit() && snapshot.data ? moment( snapshot.data[this.state.contact_person_birthday.name] , 'YYYY/MM/DD'): undefined}>
                                        <DatePicker format={'YYYY/MM/DD'} disabled={this.state.disableEdit}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={this.state.contact_person_marriage_day.name} label={this.state.contact_person_marriage_day.label} {...config}
                                               initialValue={this.isEdit() && snapshot.data ? moment( snapshot.data[this.state.contact_person_marriage_day.name] , 'YYYY/MM/DD'): undefined}>
                                        <DatePicker format={'YYYY/MM/DD'} disabled={this.state.disableEdit}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>

                            <InputText
                                values={this.state.pharmacy_name}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.pharmacy_name.name] : undefined}/>


                                </Col>  <Col span={8}>
                            <InputSelect
                                values={this.state.status}
                                options={InputFieldOptions.status}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.status.name] : 1}
                            />
                            </Col></Row>
                            <Box y={10}/>
                            <Form.Item>
                                <ButtonX
                                    htmlType="submit"
                                    name="submit"
                                    text="Submit" className={"button-default-accent"}/>
                            </Form.Item>
                            <BlocBuilder
                                subject = {AuthBloc.instance.errorText}
                                builder = {(snapshot) => {
                                    console.log(snapshot.data);
                                    return  <TextX text={snapshot.data} color={Colors.water_blue}/>
                                }}/>
                        </Form> : <FormSkeleton line={2}/>
                    }}/>
            </IsLoading>
        );
    }
}

export default withRouter(InstitutionCreatePage);

const config = {
    rules: [{ type: 'object', required: true, message: 'Please select date!' }],
};