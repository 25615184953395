import {matchRoutes, Route, Routes, useLocation} from "react-router-dom"
import {
    getRouteCreate,
    getRouteDetailsPath,
    getRouteList,
    getRouteUpdatePath,
    routeHome
} from "../../utils/RouterUtils";
import {HomePage} from "../home/HomePage";
import React from "react";
import UserCreatePage from "../user/UserCreatePage";
import {MenuConfig} from "../../utils/Constants";
import CommonListPage from "../common/CommonListPage";
import CommonDetailsPage from "../common/CommonDetailsPage";
import CommonCreatePage from "../common/CommonCreatePage";
import TerritoryCreatePage from "../territory/TerritoryCreatePage";
import DetailingCreatePage from "../detailing/DetailingCreatePage";
import SamplingProductCreatePage from "../sampling_product/SamplingProductCreatePage";
import InstitutionCreatePage from "../institution/InstitutionCreatePage";
import BrandCreatePage from "../brand/BrandCreatePage";
import LineManagerCreatePage from "../line_manager/LineManagerCreatePage";
import FieldForceCreatePage from "../field_force/FieldForceCreatePage";
import HealthcareProfessionalCreatePage from "../healthcare_professional/HealthcareProfessionalCreatePage";
import ChemistCreatePage from "../chemist/ChemistCreatePage";
import CommonCreatePageDynamic from "../common/CommonCreatePageDynamic";

export default function MainWindow2() {

    const location = useLocation();
    console.log(location.pathname);

    const menuList = [
            MenuConfig.zone, MenuConfig.territory,

            MenuConfig.line_manager, MenuConfig.field_force,
            MenuConfig.healthcare_professional, MenuConfig.chemist,
            MenuConfig.institution, MenuConfig.brand, MenuConfig.designation, MenuConfig.category,
            MenuConfig.detailing, MenuConfig.sampling_product, MenuConfig.input_product,
            MenuConfig.visit, MenuConfig.report, MenuConfig.order, MenuConfig.user_group, MenuConfig.user
        ];

    const createMenuList = [
        MenuConfig.category, MenuConfig.designation, MenuConfig.user_group
    ];

    const prepareListRoute = (menuList) => {

        return  menuList.map(e =>
            <Route key={`${e.lower}List`} path={getRouteList(e.lower)}
                   element={<CommonListPage key={`${e.lower}List`} config={e}/>} />)
    };

    const prepareDetailsRoute = (menuList) => {

        return  menuList.map(e =>
            <Route key={`${e.lower}Details`}
                   path={getRouteDetailsPath(e.lower)}
                   element={<CommonDetailsPage key={`${e.lower}Details`}  config={e}/>} />)

    };

    const prepareCreateRoute = (menuList) => {

        return  menuList.map(e =>
            <Route key={`${e.lower}Create`}
                      path={getRouteCreate(e.lower)}
               element= {<CommonCreatePage config={e}/>} />)

    };

    const prepareEditRoute = (menuList) => {

        return  menuList.map(e =>
            <Route key={`${e.lower}Edit`}
               path={getRouteUpdatePath(e.lower)}
               element = {<CommonCreatePage key={`${e.lower}Edit`} config={e} edit />} />)

    };

    return <div style={{overflowY:"auto", height:"100%"}}>
        <Routes>

            <Route path={routeHome} element={<HomePage/>} />
            
            <Route path={getRouteCreate(MenuConfig.user.lower)} element={<UserCreatePage/>}/>
            <Route path={getRouteUpdatePath(MenuConfig.user.lower)} element = {<UserCreatePage edit/>} />
            
            {prepareListRoute(menuList)}
            {prepareEditRoute(createMenuList)}
            {prepareCreateRoute(createMenuList)}

            <Route key={`${MenuConfig.zone.lower}Create`}

                   path={getRouteCreate(MenuConfig.zone.lower)}
                   element={<CommonCreatePageDynamic config={MenuConfig.zone}/>}/>

            <Route key={`${MenuConfig.zone.lower}Edit`}

                   path={getRouteUpdatePath(MenuConfig.zone.lower)}
                   element = { <CommonCreatePageDynamic edit config={MenuConfig.zone}/>} />


            <Route key={`${MenuConfig.territory.lower}Create`}

                   path={getRouteCreate(MenuConfig.territory.lower)}
                   element={<TerritoryCreatePage/>}/>

            <Route key={`${MenuConfig.territory.lower}Edit`}

                   path={getRouteUpdatePath(MenuConfig.territory.lower)}
                   element = { <TerritoryCreatePage edit />} />

            <Route key={`${MenuConfig.detailing.lower}Create`}
                   
                   path={getRouteCreate(MenuConfig.detailing.lower)}
                   element={<DetailingCreatePage/>}/>

            <Route key={`${MenuConfig.detailing.lower}Edit`}
                   
                   path={getRouteUpdatePath(MenuConfig.detailing.lower)}
                   element = {<DetailingCreatePage edit />} />

            <Route key={`${MenuConfig.sampling_product.lower}Create`}
                   
                   path={getRouteCreate(MenuConfig.sampling_product.lower)}
                   element={<SamplingProductCreatePage/>}/>

            <Route key={`${MenuConfig.sampling_product.lower}Edit`}
                   
                   path={getRouteUpdatePath(MenuConfig.sampling_product.lower)}
                   element = {<SamplingProductCreatePage edit />} />

            <Route 
                   path={getRouteCreate(MenuConfig.institution.lower)}
                   element={<InstitutionCreatePage/>}/>
            <Route 
                   path={getRouteUpdatePath(MenuConfig.institution.lower)}
                   element = {<InstitutionCreatePage edit />} />

            <Route 
                   path={getRouteCreate(MenuConfig.brand.lower)}
                   element={<BrandCreatePage/>}/>
            <Route 
                   path={getRouteUpdatePath(MenuConfig.brand.lower)}
                   element = {<BrandCreatePage edit />} />

            <Route 
                   path={getRouteCreate(MenuConfig.line_manager.lower)}
                   element={<LineManagerCreatePage/>}/>
            <Route 
                   path={getRouteUpdatePath(MenuConfig.line_manager.lower)}
                   element = {<LineManagerCreatePage edit />} />

            <Route 
                   path={getRouteCreate(MenuConfig.field_force.lower)}
                   element={<FieldForceCreatePage/>}/>
            <Route 
                   path={getRouteUpdatePath(MenuConfig.field_force.lower)}
                   element = {<FieldForceCreatePage edit />} />

            <Route 
                   path={getRouteCreate(MenuConfig.healthcare_professional.lower)}
                   element={<HealthcareProfessionalCreatePage/>}/>
            <Route 
                   path={getRouteUpdatePath(MenuConfig.healthcare_professional.lower)}
                   element = {<HealthcareProfessionalCreatePage edit />} />

            <Route 
                   path={getRouteCreate(MenuConfig.chemist.lower)}
                   element={<ChemistCreatePage/>}/>
            <Route 
                   path={getRouteUpdatePath(MenuConfig.chemist.lower)}
                   element = {<ChemistCreatePage edit />} />
            
            {prepareDetailsRoute(menuList)}

            <Route path={"/"} element={<HomePage/>} />
            

        </Routes>
    </div>
}