import React from "react";
import {Row} from "antd";
import SettingsBloc from "../../bloc/SettingsBloc";
import CrudBloc from "../../bloc/CrudBloc";
import {ButtonCreateNew} from "../shared/ButtonCreateNew";
import {ButtonExport} from "../shared/ButtonExport";
import FilterPopup from "../shared/Filter/FilterPopup";
import {getRouteCreate} from "../../utils/RouterUtils";
import {Box} from "../shared/Box";
import BlocBuilder from "../../BlocBuilder";
import TableList from "../shared/table/TableList";
import {withRouter} from "../../withRouter";


class CommonListPage extends React.Component{

    componentDidMount() {
        SettingsBloc.instance.setCurrentPageTitle(this.props.config.listTitle)
        CrudBloc.instance.getList({...this.props.location?.search}, this.props.config);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const currentSearch = this.props.location.search;
        const previousSearch = prevProps.location.search;
        if (currentSearch !== previousSearch) {
            CrudBloc.instance.getList(this.props.location.search, this.props.config);
        }
    }

    render() {
        return <div>
            <Row type="flex" justify={"space-between"}>
                <ButtonCreateNew to={getRouteCreate(this.props.config.lower)}/>
                <ButtonExport search={this.props.location.search} name={this.props.config.api} param={this.props.config.filter}/>
                <FilterPopup
                    history = {this.props.history}
                    location = {this.props.location}
                    filterParams = {this.props.config.filter}
                    queryFilterParams = {this.props.config.filter.reduce((acm, cur) => [...acm, cur.name], [])}
                />
            </Row>
            <Box y={16}/>
            <BlocBuilder
            subject = {CrudBloc.instance[this.props.config.stream]}
            builder = {(snapshot) => {
                return  <TableList
                    history={this.props.history}
                    location={this.props.location}
                    total = {snapshot.data ? snapshot.data.total: 0}
                    list={snapshot.data ? snapshot.data.list : []}
                    isFetching={false}
                    isFailed={false}
                    attributes={this.props.config.list}
                    name={this.props.config.lower}
                />
            }}/>
        </div>
    }
}

export default withRouter(CommonListPage);