import React from "react";
import BlocBuilder from "../../BlocBuilder";
import SettingsBloc from "../../bloc/SettingsBloc";

import AuthBloc from "../../bloc/AuthBloc";
import {Button, Form, Upload} from "antd";
import {Colors} from "../../css/Colors";
import {TextX} from "../shared/TextX";
import {InputText} from "../shared/FormComponent/InputText";
import {Box} from "../shared/Box";
import {ButtonX} from "../shared/ButtonX";
import {InputFields} from "../../utils/InputFields";
import {InputFieldOptions} from "../../utils/InputFieldOptions";
import CrudBloc from "../../bloc/CrudBloc";
import {getApiList, getRouteList} from "../../utils/RouterUtils";
import {InputSelect} from "../shared/FormComponent/InputSelect";
import {BASEURL, MenuConfig} from "../../utils/Constants";
import {IsLoading} from "../shared/IsLoading";
import {FormSkeleton} from "../shared/FormSkeleton";
import {UploadOutlined} from "@ant-design/icons";
import axiosWrapper from "../../utils/AxiosWrapper";
import {saveItem} from "../../utils/LocalStorage";
import moment from "moment/moment";
import _ from "lodash";
import LoadingBloc from "../../bloc/LoadingBloc";
import axios from "axios";
import {withRouter} from "../../withRouter";
import {createResponseSub, updateResponseSub} from "../common/CreateUtils";


class DetailingCreatePage extends React.Component{

    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            title: InputFields.title,
            brand_id: InputFields.brand_id,
            description: InputFields.description,
            status: InputFields.status,
            file: ''
        };

        if(this.props.edit){
            const {id} = this.props.params;
            CrudBloc.instance.getDetails(id, MenuConfig.detailing);
        }else{
            CrudBloc.instance.clearDetails(MenuConfig.detailing.lower);
        }

        CrudBloc.instance.getList({status: 1, limit: 100}, MenuConfig.brand)
    }

    componentDidMount() {
        if(this.props.edit) {
            SettingsBloc.instance.setCurrentPageTitle("Detailing Update");
            this.updateResponseSubscription = updateResponseSub(this.props.navigate,MenuConfig.detailing.lower)
        } else {
            SettingsBloc.instance.setCurrentPageTitle("Create new Detailing");
            this.createResponseSubscription = createResponseSub(this.props.navigate,MenuConfig.detailing.lower);
        }
    }

    componentWillUnmount() {
        this.createResponseSubscription?.unsubscribe();
        this.updateResponseSubscription?.unsubscribe();
    }


    onFinish = (values) => {
        console.log('Success:', values);
        console.log(this.state.file)

        LoadingBloc.instance.start(MenuConfig.detailing.lower);
        if(this.props.edit){
            const {id} = this.props.params;
            values["document"] = CrudBloc.instance.detailing.getValue().document;
            CrudBloc.instance.update(id, values, MenuConfig.detailing);
        }else{
            axiosWrapper
                .get(BASEURL+ "/imagekit")
                .then((response) => {
                    console.log(response.data);

                    const formData = new FormData();
                    formData.append("file", this.state.file);
                    formData.append("fileName", "test_client");
                    formData.append("publicKey", "public_x7qNS7VHv/6wqJpMQthTRx9Cy+g=");
                    formData.append("folder", "phyzii");
                    formData.append("signature", response.data.data.signature);
                    formData.append("expire", response.data.data.expire);
                    formData.append("token", response.data.data.token);

                    axios.post("https://upload.imagekit.io/api/v1/files/upload", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    } )
                        .then((imagekitResponse) => {
                            console.log(imagekitResponse.data);
                            values["document"] = imagekitResponse.data.url;
                            CrudBloc.instance.createNew(values, MenuConfig.detailing);
                        }).catch((error) => {
                        console.log(error)
                    });
                })
                .catch((error) => {
                    console.log(error)
                });
        }


    };

    render() {

        const props = {
            onRemove: (file) => {

            },
            beforeUpload: (file) => {
                this.setState({file: file})
                return false;
            },

        };

        return (
            <IsLoading name={MenuConfig.detailing.lower}>
                <BlocBuilder
                    subject = {CrudBloc.instance.detailing}
                    builder = {(snapshot) => {
                        console.log(snapshot.data);
                        //console.log(snapshot.data ? snapshot.data[this.state.title.name] : null);
                        return   !this.props.edit ||  snapshot.data ? <Form ref={this.formRef} name="control-ref" onFinish={this.onFinish} layout="vertical" className="create-form">
                            <InputText values={this.state.title}
                                       value={this.props.edit && snapshot.data ? snapshot.data[this.state.title.name] : undefined}/>
                            <InputText values={this.state.description}
                                       value={this.props.edit && snapshot.data ? snapshot.data[this.state.description.name] : undefined}/>

                            <BlocBuilder
                                subject = {CrudBloc.instance.brandList}
                                builder = {(snapshotRegion) => {
                                    console.log(snapshotRegion.data);
                                    return  <InputSelect
                                        values={this.state.brand_id}
                                        options={snapshotRegion.data ? snapshotRegion.data.list : []}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.brand_id.name] : undefined}
                                    />
                                }}/>

                                {this.props.edit ? null :
                            <Upload {...props} maxCount={1}>
                                <Button icon={<UploadOutlined />}>Select File</Button>
                            </Upload>}
                            <InputSelect
                                values={this.state.status}
                                options={InputFieldOptions.status}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.status.name] : 1}
                            />
                            <Box y={10}/>
                            <Form.Item>
                                <ButtonX
                                    htmlType="submit"
                                    name="submit"
                                    text="Submit" className={"button-default-accent"}/>
                            </Form.Item>
                            <BlocBuilder
                                subject = {AuthBloc.instance.errorText}
                                builder = {(snapshotError) => {
                                    //console.log(snapshotError.data);
                                    return  <TextX text={snapshotError.data} color={Colors.water_blue}/>
                                }}/>
                        </Form> : <FormSkeleton line={2}/>
                    }}/>
            </IsLoading>
        );
    }
}

export default withRouter(DetailingCreatePage);
