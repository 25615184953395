import React from "react";
import {Link} from "react-router-dom";
import "./itemAction.css";
import {Dropdown} from 'antd';
import {FileExcelOutlined, MoreOutlined} from "@ant-design/icons";

export function TableRowAction ({actions}) {

    const items = [];
    actions.forEach( action => items.push
        ({
            key: action.title,
            label: (<Link key={action.title} to={action.url}> {action.icon ? <FileExcelOutlined /> : null} {action.title}</Link>)
        })
    )

    return (
        actions.length === 0 ? <div/> :

            <Dropdown menu={{items}} placement="bottomLeft">
                <a><MoreOutlined/></a>
            </Dropdown>
    );
}
