
import {
    AimOutlined,
    AppstoreOutlined,
    BranchesOutlined, ClusterOutlined, DeploymentUnitOutlined,
    EnvironmentOutlined,
    ExperimentOutlined, FormOutlined, GroupOutlined,
    HomeOutlined,
    IdcardOutlined,
    MedicineBoxOutlined, PaperClipOutlined, PushpinOutlined, RobotOutlined,
    ShopOutlined, ShoppingCartOutlined, ShoppingOutlined,
    SolutionOutlined,
    TeamOutlined, TrophyOutlined,
    UserOutlined,
    UserSwitchOutlined,
} from '@ant-design/icons';
import { Menu, Layout } from 'antd';
import React, { useState } from 'react';
import {Link} from "react-router-dom";
import Images from "../../../utils/Images";
import BlocBuilder from "../../../BlocBuilder";
import SettingsBloc from "../../../bloc/SettingsBloc";
import './NavigationMenu.css';
import {MenuConfig} from "../../../utils/Constants";
import {getRouteList, routeHome} from "../../../utils/RouterUtils";

const { Sider } = Layout;

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}
function prepareMenu({config, path, icon }) {
    return {
        label: (<Link key={config.lower+"-link"} to={path} style={{color: "#000000"}}>{config.upper}</Link>),
        key: config.lower,
        icon: icon,
    }
}

const routes = {
    home: {config: MenuConfig.home, path: routeHome, icon: <HomeOutlined/>},

    zone: {config: MenuConfig.zone, path: getRouteList(MenuConfig.zone.lower), icon: <DeploymentUnitOutlined />, menu: "locations"},
    territory: {config: MenuConfig.territory, path: getRouteList(MenuConfig.territory.lower), icon: <ClusterOutlined />, menu: "locations"},

    user: {config: MenuConfig.user, path: getRouteList(MenuConfig.user.lower), icon: <UserOutlined />, menu: "users"},
    user_group: {config: MenuConfig.user_group, path: getRouteList(MenuConfig.user_group.lower), icon: <TeamOutlined />, menu: "users"},

    line_manager: {config: MenuConfig.line_manager, path: getRouteList(MenuConfig.line_manager.lower), icon: <TeamOutlined />, menu: "managers"},
    field_force: {config: MenuConfig.field_force, path: getRouteList(MenuConfig.field_force.lower), icon: <SolutionOutlined />, menu: "managers"},

    healthcare_professional: {config: MenuConfig.healthcare_professional, path: getRouteList(MenuConfig.healthcare_professional.lower), icon: <MedicineBoxOutlined />, menu: "items"},
    chemist: {config: MenuConfig.chemist, path: getRouteList(MenuConfig.chemist.lower), icon: <ExperimentOutlined />, menu: "items"},

    institution: {config: MenuConfig.institution, path: getRouteList(MenuConfig.institution.lower), icon: <ShopOutlined />, menu: "items"},
    brand: {config: MenuConfig.brand, path: getRouteList(MenuConfig.brand.lower), icon: <ShoppingOutlined />, menu: "items"},
    designation: {config: MenuConfig.designation, path: getRouteList(MenuConfig.designation.lower), icon: <TrophyOutlined />, menu: "items"},
    category: {config: MenuConfig.category, path: getRouteList(MenuConfig.category.lower), icon: <GroupOutlined />, menu: "items"},
    detailing: {config: MenuConfig.detailing, path: getRouteList(MenuConfig.detailing.lower), icon: <RobotOutlined />, menu: "items"},

    sampling_product: {config: MenuConfig.sampling_product, path: getRouteList(MenuConfig.sampling_product.lower), icon: <PaperClipOutlined />, menu: "items"},
    input_product: {config: MenuConfig.input_product, path: getRouteList(MenuConfig.input_product.lower), icon: <PushpinOutlined />, menu: "items"},

    visit: {config: MenuConfig.visit, path: getRouteList(MenuConfig.visit.lower), icon: <AimOutlined />, menu: "items"},
    report: {config: MenuConfig.report, path: getRouteList(MenuConfig.report.lower), icon: <FormOutlined />, menu: "items"},
    order: {config: MenuConfig.order, path: getRouteList(MenuConfig.order.lower), icon: <ShoppingCartOutlined />, menu: "items"},
    
}

const items = [
    prepareMenu(routes.home),

    prepareMenu(routes.report),

    getItem('Users', 'users', <UserOutlined/>, [
        prepareMenu(routes.user),
        prepareMenu(routes.user_group),
    ]),
    getItem('Managers', 'managers', <IdcardOutlined />, [
        prepareMenu(routes.line_manager),
        prepareMenu(routes.field_force),
    ]),
    getItem('Locations', 'locations', <EnvironmentOutlined />, [
        prepareMenu(routes.zone),
        prepareMenu(routes.territory),
    ]),
    getItem('Items', 'items', <AppstoreOutlined />, [
        prepareMenu(routes.chemist),
        prepareMenu(routes.healthcare_professional),
        prepareMenu(routes.category),
        //prepareMenu(routes.designation),
        prepareMenu(routes.institution),
        prepareMenu(routes.brand),
        prepareMenu(routes.detailing),
        prepareMenu(routes.sampling_product),
        // prepareMenu(routes.input_product),
    ]),
];
const NavigationMenu = () => {
    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    return (
        <BlocBuilder
            subject={SettingsBloc.instance.sideBarCollapsed}
            builder={(snapshot) => {
                return <BlocBuilder
                    subject={SettingsBloc.instance.currentRoute}
                    builder={(snapshotRoute) => {
                        // console.log(snapshotRoute.data)
                        return <BlocBuilder
                            subject={SettingsBloc.instance.currentMenu}
                            builder={(snapshotMenu) => {
                                // console.log(snapshotMenu.data)
                                return <Sider trigger={null} collapsible collapsed={snapshot.data} style={{width:230}}>
                                    <Link key={"home-link"} to={"/dashboard"}>
                                        <div className="side-logo">

                                            <img
                                                style={{marginRight: 0, marginLeft: 32, filter: "brightness(0) invert(1)"}}
                                                width={40}
                                                src={Images.logoUnilever}
                                            />
                                            <img
                                                width={50}
                                                src={Images.logo}
                                            />
                                        </div>
                                    </Link>
                                    <Menu
                                        defaultSelectedKeys={['1']}
                                        defaultOpenKeys={['sub1']}
                                        mode="inline"
                                        theme="dark"
                                        collapsed={collapsed === true ? collapsed: undefined}
                                        items={items}
                                    />
                                </Sider>
                            }}/>
                    }}/>
            }}/>

    );
};
export default NavigationMenu;