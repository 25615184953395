import React from "react";
import SettingsBloc from "../../bloc/SettingsBloc";
import CrudBloc from "../../bloc/CrudBloc";

import {TableDetails} from "../shared/table/TableDetails";
import BlocBuilder from "../../BlocBuilder";
import {withRouter} from "../../withRouter";


class CommonDetailsPage extends React.Component{
    componentDidMount() {
        const {id} = this.props.params;
        SettingsBloc.instance.setCurrentPageTitle(this.props.config.upper)
        CrudBloc.instance.getDetails(id, this.props.config);
    }

    render() {
        return <BlocBuilder
            subject = {CrudBloc.instance[this.props.config.lower]}
            builder = {(snapshot) => {
                console.log(snapshot.data);
                return  <TableDetails
                    history={this.props.history}
                    location={this.props.location}
                    data={snapshot.data ? snapshot.data : null}
                    isFetching={false}
                    isFailed={false}
                    attributes={this.props.config.details}
                    name={this.props.config.lower}
                />
            }}/>
    }
}
export default withRouter(CommonDetailsPage);
