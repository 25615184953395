import React from "react";
import BlocBuilder from "../../BlocBuilder";
import SettingsBloc from "../../bloc/SettingsBloc";

import AuthBloc from "../../bloc/AuthBloc";
import {Form} from "antd";
import {Colors} from "../../css/Colors";
import {TextX} from "../shared/TextX";
import {InputText} from "../shared/FormComponent/InputText";
import {Box} from "../shared/Box";
import {ButtonX} from "../shared/ButtonX";
import {InputFields} from "../../utils/InputFields";
import {InputFieldOptions} from "../../utils/InputFieldOptions";
import CrudBloc from "../../bloc/CrudBloc";
import {getRouteList} from "../../utils/RouterUtils";
import {InputSelect} from "../shared/FormComponent/InputSelect";
import {MenuConfig} from "../../utils/Constants";
import {IsLoading} from "../shared/IsLoading";
import {FormSkeleton} from "../shared/FormSkeleton";
import {withRouter} from "../../withRouter";
import {createResponseSub, updateResponseSub} from "../common/CreateUtils";

class SamplingProductCreatePage extends React.Component{

    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            title: InputFields.title,
            description: InputFields.description,
            brand_id: InputFields.brand_id,
            status: InputFields.status
        };

        if(this.props.edit){
            const {id} = this.props.params;
            CrudBloc.instance.getDetails(id, MenuConfig.sampling_product);
        }else{
            CrudBloc.instance.clearDetails(MenuConfig.sampling_product.lower);
        }

        CrudBloc.instance.getList({status: 1, limit: 100}, MenuConfig.brand)
    }



    componentDidMount() {
        if(this.props.edit) {
            SettingsBloc.instance.setCurrentPageTitle("Sampling Product Update");
            this.updateResponseSubscription = updateResponseSub(this.props.navigate,MenuConfig.sampling_product.lower)
        } else {
            SettingsBloc.instance.setCurrentPageTitle("Create new Sampling Product");
            this.createResponseSubscription = createResponseSub(this.props.navigate,MenuConfig.sampling_product.lower);
        }
    }

    onFinish = (values) => {
        console.log('Success:', values);
        if(this.props.edit){
            const {id} = this.props.params;
            CrudBloc.instance.update(id, values, MenuConfig.sampling_product);
        }else{
            CrudBloc.instance.createNew(values, MenuConfig.sampling_product);
        }

    };

    componentWillUnmount() {
        this.createResponseSubscription?.unsubscribe();
        this.updateResponseSubscription?.unsubscribe();
    }

    render() {
        return (
            <IsLoading name={MenuConfig.sampling_product.lower}>
                <BlocBuilder
                    subject = {CrudBloc.instance.sampling_product}
                    builder = {(snapshot) => {
                        console.log(snapshot.data);
                        //console.log(snapshot.data ? snapshot.data[this.state.title.name] : null);
                        return   !this.props.edit ||  snapshot.data ? <Form ref={this.formRef} name="control-ref" onFinish={this.onFinish} layout="vertical" className="create-form">
                            <InputText
                                values={this.state.title}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.title.name] : undefined}/>
                            <InputText values={this.state.description}
                                       value={this.props.edit && snapshot.data ? snapshot.data[this.state.description.name] : undefined}/>

                            <BlocBuilder
                                subject = {CrudBloc.instance.brandList}
                                builder = {(snapshotRegion) => {
                                    console.log(snapshotRegion.data);
                                    return  <InputSelect
                                        values={this.state.brand_id}
                                        options={snapshotRegion.data ? snapshotRegion.data.list : []}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.brand_id.name] : undefined}
                                    />
                                }}/>
                            <InputSelect
                                values={this.state.status}
                                options={InputFieldOptions.status}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.status.name] : 1}
                            />
                            <Box y={10}/>
                            <Form.Item>
                                <ButtonX
                                    htmlType="submit"
                                    name="submit"
                                    text="Submit" className={"button-default-accent"}/>
                            </Form.Item>
                            <BlocBuilder
                                subject = {AuthBloc.instance.errorText}
                                builder = {(snapshotError) => {
                                    //console.log(snapshotError.data);
                                    return  <TextX text={snapshotError.data} color={Colors.water_blue}/>
                                }}/>
                        </Form> : <FormSkeleton line={2}/>
                    }}/>
            </IsLoading>
        );
    }
}

export default withRouter(SamplingProductCreatePage);