import React from "react";

export class HomePage extends React.Component {

    selectedYear = "2023";
    componentDidMount() {

    }

    render() {
        return <div style={{width: "99%"}}>

        </div>
    }
}