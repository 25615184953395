import React from "react";
import SettingsBloc from "../../bloc/SettingsBloc";
import AuthBloc from "../../bloc/AuthBloc";
import {Form} from "antd";
import {Colors} from "../../css/Colors";
import {TextX} from "../shared/TextX";
import {InputText} from "../shared/FormComponent/InputText";
import {Box} from "../shared/Box";
import {ButtonX} from "../shared/ButtonX";
import {InputFields} from "../../utils/InputFields";
import CrudBloc from "../../bloc/CrudBloc";
import {getRouteList} from "../../utils/RouterUtils";
import {InputFieldOptions} from "../../utils/InputFieldOptions";
import {InputSelect} from "../shared/FormComponent/InputSelect";
import {IsLoading} from "../shared/IsLoading";
import {FormSkeleton} from "../shared/FormSkeleton";
import BlocBuilder from "../../BlocBuilder";
import {withRouter} from "../../withRouter";
import {componentDidMount, createResponseSub, onFormFinish, prepareCrete, updateResponseSub} from "./CreateUtils";



class CommonCreatePageDynamic extends React.Component{

    formRef = React.createRef();
    config = this.props.config;
    constructor(props) {
        super(props);

        var result = Object.assign({}, ...(this.config.fields.map(item => ({ [item.name]: item }) )));
        console.log(result)

        this.state = result

        if(this.props.edit){
            const {id} = this.props.params;
            CrudBloc.instance.getDetails(id, this.config);
        }else{
            CrudBloc.instance.clearDetails(this.config.lower);
        }
    }

    componentDidMount() {
        if(this.props.edit) {
            SettingsBloc.instance.setCurrentPageTitle(`${this.config.upper} Update`);
            this.updateResponseSubscription = updateResponseSub(this.props.navigate, this.config.lower)
        } else {
            SettingsBloc.instance.setCurrentPageTitle(`Create new ${this.config.upper}`);
            this.createResponseSubscription = createResponseSub(this.props.navigate, this.config.lower);
        }
    }

    onFinish = (values) => {

        console.log('Success:', values);
        if(this.props.edit) {
            const {id} = this.props.params;
            CrudBloc.instance.update(id, values, this.config);
        } else {
            CrudBloc.instance.createNew(values, this.config);
        }
    };

    componentWillUnmount() {
        this.createResponseSubscription?.unsubscribe();
        this.updateResponseSubscription?.unsubscribe();
    }

    render() {

        return (
            <IsLoading name={this.config.lower}>
                <BlocBuilder
                    subject = {CrudBloc.instance[this.config.lower]}
                    builder = {(snapshot) => {
                        return   !this.props.edit ||  snapshot.data ?
                            <Form ref={this.formRef} name="control-ref" onFinish={this.onFinish} layout="vertical" className="create-form">
                                {
                                    this.config.fields.map( e => {
                                        if(e.name === "title"){
                                            return <InputText
                                                key={this.state.title.name}
                                                values={this.state.title}
                                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.title.name] : undefined}/>
                                        }
                                        else if(e.name === "code"){
                                            return <InputText
                                                key={this.state.code.name}
                                                values={this.state.code}
                                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.code.name] : undefined}/>

                                        }
                                        else if(e.name === "status"){
                                            return <InputSelect
                                                key={this.state.status.name}
                                                values={this.state.status}
                                                options={InputFieldOptions.status}
                                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.status.name] : 1}
                                            />
                                        }
                                    })
                                }

                            <Box y={10}/>
                            <Form.Item>
                                <ButtonX
                                    htmlType="submit"
                                    name="submit"
                                    text="Submit" className={"button-default-accent"}/>
                            </Form.Item>
                            <BlocBuilder
                                subject = {AuthBloc.instance.errorText}
                                builder = {(snapshot) => {
                                    return  <TextX text={snapshot.data} color={Colors.water_blue}/>
                                }}/>
                        </Form> : <FormSkeleton line={2}/>
                    }}/>
            </IsLoading>
        );
    }
}

export default withRouter(CommonCreatePageDynamic);