export const InputFieldOptions = {
    status: [
        {id: 1, title: "Active"},
        {id: 2, title: "In-Active"},
    ],

    prescription_habit: [
        {id: 1, title: "Prescriber"},
        {id: 2, title: "Non-Prescriber"},
        {id: 3, title: "Intermittent"},
    ],


    availability_status: [
        {id: 1, title: "Yes"},
        {id: 2, title: "No"},
    ],
    relationship: [
        {id: 1, title: "Hot"},
        {id: 2, title: "Warm"},
        {id: 2, title: "Cold"},
    ],
    influence: [
        {id: 1, title: "High"},
        {id: 2, title: "Medium"},
        {id: 3, title: "Low"},
    ],

    class: [
        {id: 1, title: "A"},
        {id: 2, title: "B"},
    ],

    pronounce_title:[
        {id: 1, title: "Dr"},
        {id: 2, title: "Mr"},
        {id: 3, title: "Mrs"},
    ],

    year: [
        {id: 2018, title: "2018"},
        {id: 2019, title: "2019"},
        {id: 2020, title: "2020"},
        {id: 2021, title: "2021"},
        {id: 2022, title: "2022"},
    ]
}

