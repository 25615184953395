import {getRouteList} from "../../utils/RouterUtils";
import {MenuConfig} from "../../utils/Constants";
import CrudBloc from "../../bloc/CrudBloc";
import SettingsBloc from "../../bloc/SettingsBloc";
import {message} from "antd";


export function createResponseSub (navigate, path){
    return CrudBloc.instance.createResponse.subscribe({
        next(x) {
            if(x) {
                message.info("Created");
                console.log(getRouteList(path));
                navigate(getRouteList(path));
                CrudBloc.instance.clearCreateResponseData();
            }
        },
        error(err) { console.error('something wrong occurred: ' + err); },
        complete() { console.log('done'); }
    });
}

export function updateResponseSub (navigate, path, callback){
    return CrudBloc.instance.updateResponse.subscribe({

        next(x) {
            if(x) {
                if(callback != null){
                    callback();
                }else {
                    message.info("Updated");
                }


                console.log(getRouteList(path));
                navigate(getRouteList(path));
                CrudBloc.instance.clearUpdateResponseData();
            }
        },
        error(err) { console.error('something wrong occurred: ' + err); },
        complete() { console.log('done'); }
    });
}