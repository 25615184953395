import React from "react";
import BlocBuilder from "../../BlocBuilder";
import SettingsBloc from "../../bloc/SettingsBloc";

import AuthBloc from "../../bloc/AuthBloc";
import {Col, DatePicker, Form, Row} from "antd";
import {Colors} from "../../css/Colors";
import {TextX} from "../shared/TextX";
import {InputText} from "../shared/FormComponent/InputText";
import {Box} from "../shared/Box";
import {ButtonX} from "../shared/ButtonX";
import {InputFields} from "../../utils/InputFields";
import CrudBloc from "../../bloc/CrudBloc";
import {getRouteList} from "../../utils/RouterUtils";
import {InputSelect} from "../shared/FormComponent/InputSelect";
import {MenuConfig} from "../../utils/Constants";
import {InputFieldOptions} from "../../utils/InputFieldOptions";
import {IsLoading} from "../shared/IsLoading";
import {FormSkeleton} from "../shared/FormSkeleton";
import {getCode} from "../../utils/Utils";
import moment from "moment";
import {withRouter} from "../../withRouter";
import {createResponseSub, updateResponseSub} from "../common/CreateUtils";


class HealthcareProfessionalCreatePage extends React.Component{


    formRef = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            pronounce_title: InputFields.pronounce_title,
            fist_name: InputFields.first_name,
            middle_name: InputFields.middle_name,
            last_name: InputFields.last_name,

            designation: InputFields.designation,

            birthday: InputFields.birthday,
            marriage_day: InputFields.marriage_day,
            mobile: InputFields.mobile,
            category_id: InputFields.category_id,

            patient_count: InputFields.patient_count,
            counselling_count: InputFields.counselling_count,
            influence_count: InputFields.influence_count,
            consultation_value: InputFields.consultation_value,

            //class_id: InputFields.class_id,
            //visit_frequency: InputFields.visit_frequency,
            professional_degree: InputFields.professional_degree,
            chamber_name: InputFields.chamber_name,

            kol: InputFields.kol,
            kp: InputFields.kp,
            relationship: InputFields.relationship,
            prescription_habit: InputFields.prescription_habit,
            prescription_share: InputFields.prescription_share,


            address_line_1_house_road: InputFields.address_line_1_house_road,
            address_line_2_city: InputFields.address_line_2_city,
            address_line_3_district: InputFields.address_line_3_district,
            institution_id: InputFields.institution_id,
            //code: InputFields.code,
            field_force_id: InputFields.field_force_id,
            status: InputFields.status
        };

        if(this.props.edit){
            const {id} = this.props.params;
            CrudBloc.instance.getDetails(id, MenuConfig.healthcare_professional.lower);
        }else{
            CrudBloc.instance.clearDetails(MenuConfig.healthcare_professional.lower);
        }

        CrudBloc.instance.getList({status: 1, limit: 100}, MenuConfig.category);
        CrudBloc.instance.getList({status: 1, limit: 100}, MenuConfig.institution);
        CrudBloc.instance.getList({status: 1, limit: 100}, MenuConfig.field_force);
    }

    componentDidMount() {
        if(this.props.edit) {
            SettingsBloc.instance.setCurrentPageTitle("Healthcare Professional Update");
            this.updateResponseSubscription = updateResponseSub(this.props.navigate,MenuConfig.chemist.lower)
        } else {
            SettingsBloc.instance.setCurrentPageTitle("Create new Healthcare Professional");
            this.createResponseSubscription = createResponseSub(this.props.navigate,MenuConfig.healthcare_professional.lower);
        }
    }

    componentWillUnmount() {
        this.createResponseSubscription?.unsubscribe();
        this.updateResponseSubscription?.unsubscribe();
    }

    onFinish = (values) => {
        console.log('Success:', values);
        if(this.props.edit){
            const {id} = this.props.params;
            CrudBloc.instance.update(id, values, MenuConfig.healthcare_professional);
        }else{
            values[this.state.birthday.name] = moment(values[this.state.birthday.name]).format("YYYY-MM-DD 10:00:00");
            values[this.state.marriage_day.name] = moment(values[this.state.marriage_day.name]).format("YYYY-MM-DD 10:00:00");
            CrudBloc.instance.field_forceList.getValue().list.forEach(e => {
                if(`${e.id}` === values["field_force_id"]){
                    values["territory_code"] = e.territory_code;
                }} )

            //=IF(SUM(Q5:T5)>13,"A",(IF(SUM(Q5:T5)>9,"B",(IF(SUM(Q5:T5)=8,"C",(IF(SUM(Q5:T5)<8,"N/A")))))))
            //=IF(U5="A",4,IF(U5="B",3,IF(U5="C",1)))
            values["class"] = this.calculateClass(values);
            values["visit_frequency"] = values["class"] === "A" ? 4 : values["class"] === "B" ? 3 : values["class"] === "C" ? 2 : 1
            CrudBloc.instance.createNew(values, MenuConfig.healthcare_professional);
        }

    };

    calculateClass(values) {
        let sum = values["counselling_count"] + values["consultation_value"] + values["influence_count"] + values["patient_count"];
        if(sum >= 16){
            return "A";
        }else if(sum >= 12){
            return "B";
        }else if(sum >= 6){
            return "C";
        }else{
            return "D";
        }
    }

    isEdit = () => {
        return this.props.edit;
    }

    render() {
        return (
            <IsLoading name={MenuConfig.healthcare_professional.lower}>
                <BlocBuilder
                    subject = {CrudBloc.instance.healthcare_professional}
                    builder = {(snapshot) => {
                        console.log(snapshot.data);
                        //console.log(snapshot.data ? snapshot.data[this.state.title.name] : null);
                        return   !this.props.edit ||  snapshot.data ? <Form ref={this.formRef} name="control-ref" onFinish={this.onFinish} layout="vertical" className="create-form-full">

                            <Row gutter={16}>
                                <Col span={3}>
                                    <InputSelect
                                        values={this.state.pronounce_title}
                                        options={InputFieldOptions.pronounce_title}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.pronounce_title.name] : undefined}/>
                                </Col>
                                <Col span={7}>
                                    <InputText
                                        values={this.state.fist_name}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.fist_name.name] : undefined}/>
                                </Col>
                                <Col span={7}>
                                    <InputText
                                        values={this.state.middle_name}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.middle_name.name] : undefined}/>
                                </Col>
                                <Col span={7}>
                                    <InputText
                                        values={this.state.last_name}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.last_name.name] : undefined}/>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <InputText
                                        values={this.state.designation}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.designation.name] : undefined}/>
                                </Col>

                                <Col span={8}>

                            <InputText
                                values={this.state.mobile}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.mobile.name] : undefined}/>
                                </Col>
                                <Col span={8}>

                            <BlocBuilder
                                subject = {CrudBloc.instance.categoryList}
                                builder = {(snapshotCategory) => {
                                    return  <InputSelect
                                        values={this.state.category_id}
                                        value={this.props.edit && snapshot.data ?  getCode(snapshot.data, this.state.category_id.name) : undefined}
                                        options={snapshotCategory.data ? snapshotCategory.data.list : []}
                                    />
                                }}/>
                                </Col>

                            </Row>

                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item name={this.state.birthday.name} label={this.state.birthday.label} {...config}

                                               initialValue={this.isEdit() && snapshot.data ? moment( snapshot.data[this.state.birthday.name] , 'YYYY/MM/DD'): undefined}>
                                        <DatePicker format={'YYYY/MM/DD'} disabled={this.state.disableEdit}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={this.state.marriage_day.name} label={this.state.marriage_day.label} {...config}
                                               initialValue={this.isEdit() && snapshot.data ? moment( snapshot.data[this.state.marriage_day.name] , 'YYYY/MM/DD'): undefined}>
                                        <DatePicker format={'YYYY/MM/DD'} disabled={this.state.disableEdit}/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={4}>
                                    <InputText
                                        values={this.state.patient_count}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.patient_count.name] : undefined}/>
                                </Col>
                                <Col span={4}>
                                    <InputText
                                        values={this.state.counselling_count}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.counselling_count.name] : undefined}/>
                                </Col>
                                <Col span={4}>
                                    <InputText
                                        values={this.state.influence_count}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.influence_count.name] : undefined}/>
                                </Col>
                                <Col span={4}>
                                    <InputText
                                        values={this.state.consultation_value}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.consultation_value.name] : undefined}/>
                                </Col>
                                <Col span={4}>
                                    <InputText
                                        values={this.state.prescription_share}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.prescription_share.name] : undefined}/>
                                </Col>
                                {/*<Col span={4}>*/}
                                {/*    <InputText*/}
                                {/*        values={this.state.visit_frequency}*/}
                                {/*        value={this.props.edit && snapshot.data ? snapshot.data[this.state.visit_frequency.name] : undefined}/>*/}
                                {/*</Col>*/}
                                {/*<Col span={4}>*/}
                                {/*    <InputSelect*/}
                                {/*        values={this.state.class_id}*/}
                                {/*        options={InputFieldOptions.class}*/}
                                {/*        value={this.props.edit && snapshot.data ? snapshot.data[this.state.class_id.name] : undefined}/>*/}
                                {/*</Col>*/}
                            </Row>

                            <Row gutter={16}>
                                <Col span={8}>
                                    <InputText
                                        values={this.state.professional_degree}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.professional_degree.name] : undefined}/>
                                </Col>
                                <Col span={8}>
                                    <InputText
                                        values={this.state.chamber_name}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.chamber_name.name] : undefined}/>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={8}>

                                    <InputText
                                        values={this.state.address_line_1_house_road}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.address_line_1_house_road.name] : undefined}/>
                                </Col>  <Col span={8}>
                                <InputText
                                    values={this.state.address_line_2_city}
                                    value={this.props.edit && snapshot.data ? snapshot.data[this.state.address_line_2_city.name] : undefined}/>
                            </Col>
                                <Col span={8}>
                                    <InputText
                                        values={this.state.address_line_3_district}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.address_line_3_district.name] : undefined}/>

                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={6}>
                                    <InputSelect
                                        values={this.state.kol}
                                        options={InputFieldOptions.availability_status}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.kol.name] : "Yes"}
                                    />
                                </Col>
                                <Col span={6}>
                                    <InputSelect
                                        values={this.state.kp}
                                        options={InputFieldOptions.availability_status}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.kp.name] : "Yes"}
                                    />
                                </Col>
                                <Col span={6}>
                                    <InputSelect
                                        values={this.state.relationship}
                                        options={InputFieldOptions.relationship}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.relationship.name] : "Hot"}
                                    />
                                </Col>
                                <Col span={6}>
                                    <InputSelect
                                        values={this.state.prescription_habit}
                                        options={InputFieldOptions.prescription_habit}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.prescription_habit.name] : "Prescriber"}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <BlocBuilder
                                        subject = {CrudBloc.instance.institutionList}
                                        builder = {(snapshotInstitution) => {
                                            return  <InputSelect
                                                values={this.state.institution_id}
                                                options={snapshotInstitution.data ? snapshotInstitution.data.list : []}
                                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.institution_id.name] : undefined}
                                            />
                                        }}/>
                                </Col>
                                <Col span={8}>
                                    <BlocBuilder
                                        subject = {CrudBloc.instance.field_forceList}
                                        builder = {(snapshotFieldForce) => {
                                            return  <InputSelect
                                                values={this.state.field_force_id}
                                                options={snapshotFieldForce.data ? snapshotFieldForce.data.list : []}
                                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.field_force_id.name] : undefined}
                                            />
                                        }}/>
                                </Col>
                                <Col span={8}>
                                <InputSelect
                                    values={this.state.status}
                                    options={InputFieldOptions.status}
                                    value={this.props.edit && snapshot.data ? snapshot.data[this.state.status.name] : 1}
                                />
                            </Col></Row>


                            <Box y={10}/>
                            <Form.Item>
                                <ButtonX
                                    htmlType="submit"
                                    name="submit"
                                    text="Submit" className={"button-default-accent"}/>
                            </Form.Item>
                            <BlocBuilder
                                subject = {AuthBloc.instance.errorText}
                                builder = {(snapshotError) => {
                                    //console.log(snapshotError.data);
                                    return  <TextX text={snapshotError.data} color={Colors.water_blue}/>
                                }}/>
                        </Form> : <FormSkeleton line={2}/>
                    }}/>
            </IsLoading>
        );
    }
}

export default withRouter(HealthcareProfessionalCreatePage);

const config = {
    rules: [{ type: 'object', required: false, message: 'Please select date!' }],
};