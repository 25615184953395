import React from "react";
import BlocBuilder from "../../BlocBuilder";
import SettingsBloc from "../../bloc/SettingsBloc";

import AuthBloc from "../../bloc/AuthBloc";
import {Col, DatePicker, Form, Row} from "antd";
import {Colors} from "../../css/Colors";
import {TextX} from "../shared/TextX";
import {InputText} from "../shared/FormComponent/InputText";
import {Box} from "../shared/Box";
import {ButtonX} from "../shared/ButtonX";
import {InputFields} from "../../utils/InputFields";
import CrudBloc from "../../bloc/CrudBloc";
import {getRouteList} from "../../utils/RouterUtils";
import {InputSelect} from "../shared/FormComponent/InputSelect";
import {MenuConfig} from "../../utils/Constants";
import {InputFieldOptions} from "../../utils/InputFieldOptions";
import {IsLoading} from "../shared/IsLoading";
import {FormSkeleton} from "../shared/FormSkeleton";
import moment from "moment";
import {withRouter} from "../../withRouter";
import {createResponseSub, updateResponseSub} from "../common/CreateUtils";
import LocationBloc from "../../bloc/LocationBloc";


class ChemistCreatePage extends React.Component{


    formRef = React.createRef();
    constructor(props) {
        super(props);
        //

        this.state = {
            fist_name: InputFields.first_name,
            middle_name: InputFields.middle_name,
            last_name: InputFields.last_name,
            birthday: InputFields.birthday,
            marriage_day: InputFields.marriage_day,
            mobile: InputFields.mobile,
            pharmacy_name: InputFields.pharmacy_name,
            address_line_1_house_road: InputFields.address_line_1_house_road,
            address_line_2_city: InputFields.address_line_2_city,
            address_line_3_district: InputFields.address_line_3_district,
            institution_id: InputFields.institution_id,
            field_force_id: InputFields.field_force_id,

            product_availability: InputFields.product_availability,
            influence: InputFields.influence,
            relationship: InputFields.relationship,
            status: InputFields.status
        };

        if(this.props.edit){
            const {id} = this.props.params;
            CrudBloc.instance.getDetails(id, MenuConfig.chemist.lower);
        }else{
            CrudBloc.instance.clearDetails(MenuConfig.chemist.lower);
        }
        CrudBloc.instance.getList({status: 1}, MenuConfig.field_force);
        CrudBloc.instance.getList({status: 1}, MenuConfig.institution)

    }

    componentDidMount() {
        if(this.props.edit) {
            SettingsBloc.instance.setCurrentPageTitle("Chemist Update");
            this.updateResponseSubscription = updateResponseSub(this.props.navigate,MenuConfig.chemist.lower)
        } else {
            SettingsBloc.instance.setCurrentPageTitle("Create new Chemist");
            this.createResponseSubscription = createResponseSub(this.props.navigate,MenuConfig.chemist.lower);
        }
    }

    componentWillUnmount() {
        this.createResponseSubscription?.unsubscribe();
        this.updateResponseSubscription?.unsubscribe();
    }

    onFinish = (values) => {
        console.log('Success:', values);
        if(this.props.edit){
            const {id} = this.props.params;
            CrudBloc.instance.update(id, values, MenuConfig.chemist);
        }else{
            values[this.state.birthday.name] = moment(values[this.state.birthday.name]).format("YYYY-MM-DD 10:00:00");
            values[this.state.marriage_day.name] = moment(values[this.state.marriage_day.name]).format("YYYY-MM-DD 10:00:00");

            CrudBloc.instance.field_forceList.getValue().list.forEach(e => {
                if(`${e.id}` === values["field_force_id"]){
                    values["territory_code"] = e.territory_code;
                }} )
            CrudBloc.instance.createNew(values, MenuConfig.chemist);
        }

        console.log('Success:', values);

    };

    isEdit = () => {
        return this.props.edit;
    }

    render() {
        return (
            <IsLoading name={MenuConfig.chemist.lower}>
                <BlocBuilder
                    subject = {CrudBloc.instance.chemist}
                    builder = {(snapshot) => {
                        console.log(snapshot.data);
                        //console.log(snapshot.data ? snapshot.data[this.state.title.name] : null);
                        return   !this.props.edit ||  snapshot.data ? <Form ref={this.formRef} name="control-ref" onFinish={this.onFinish} layout="vertical" className="create-form-full">

                            <Row gutter={16}>
                                <Col span={8}>
                                <InputText
                                    values={this.state.fist_name}
                                    value={this.props.edit && snapshot.data ? snapshot.data[this.state.fist_name.name] : undefined}/>
                                </Col>
                                <Col span={8}>
                                <InputText
                                    values={this.state.middle_name}
                                    value={this.props.edit && snapshot.data ? snapshot.data[this.state.middle_name.name] : undefined}/>
                                </Col>
                                <Col span={8}>
                                <InputText
                                    values={this.state.last_name}
                                    value={this.props.edit && snapshot.data ? snapshot.data[this.state.last_name.name] : undefined}/>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                <Form.Item name={this.state.birthday.name} label={this.state.birthday.label} {...config}

                                           initialValue={this.isEdit() && snapshot.data ? moment( snapshot.data[this.state.birthday.name] , 'YYYY/MM/DD'): undefined}>
                                    <DatePicker format={'YYYY/MM/DD'} disabled={this.state.disableEdit}/>
                                </Form.Item>
                                </Col>
                                <Col span={8}>
                                <Form.Item name={this.state.marriage_day.name} label={this.state.marriage_day.label} {...config}
                                           initialValue={this.isEdit() && snapshot.data ? moment( snapshot.data[this.state.marriage_day.name] , 'YYYY/MM/DD'): undefined}>
                                    <DatePicker format={'YYYY/MM/DD'} disabled={this.state.disableEdit}/>
                                </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={8}>

                            <InputText
                                values={this.state.mobile}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.mobile.name] : undefined}/>
                                </Col> <Col span={8}>
                            <InputText
                                values={this.state.pharmacy_name}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.pharmacy_name.name] : undefined}/>
                                </Col>
                                {/*<Col span={8}>*/}
                                {/*    <InputText*/}
                                {/*        values={this.state.code}*/}
                                {/*        value={this.props.edit && snapshot.data ? snapshot.data[this.state.code.name] : undefined}/>*/}
                                {/*</Col>*/}
                            </Row>

                            <Row gutter={16}>
                                <Col span={8}>

                            <InputText
                                values={this.state.address_line_1_house_road}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.address_line_1_house_road.name] : undefined}/>
                                </Col>  <Col span={8}>
                            <InputText
                                values={this.state.address_line_2_city}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.address_line_2_city.name] : undefined}/>
                                    </Col>
                                    <Col span={8}>
                            <InputText
                                values={this.state.address_line_3_district}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.address_line_3_district.name] : undefined}/>

                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                            <BlocBuilder
                                subject = {CrudBloc.instance.institutionList}
                                builder = {(snapshotInstitution) => {
                                    return  <InputSelect
                                        values={this.state.institution_id}
                                        options={snapshotInstitution.data ? snapshotInstitution.data.list : []}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.institution_id.name] : undefined}
                                    />
                                }}/>
                                </Col>
                                <Col span={8}>
                                    <BlocBuilder
                                        subject = {CrudBloc.instance.field_forceList}
                                        builder = {(snapshotFieldForce) => {
                                            return  <InputSelect
                                                values={this.state.field_force_id}
                                                options={snapshotFieldForce.data ? snapshotFieldForce.data.list : []}
                                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.field_force_id.name] : undefined}
                                            />
                                        }}/>
                                </Col>

                                <Col span={8}>
                                <InputSelect
                                    values={this.state.status}
                                    options={InputFieldOptions.status}
                                    value={this.props.edit && snapshot.data ? snapshot.data[this.state.status.name] : 1}
                                />
                            </Col></Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <InputSelect
                                        values={this.state.product_availability}
                                        options={InputFieldOptions.availability_status}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.product_availability.name] : "Yes"}
                                    />
                                </Col>
                                <Col span={8}>
                                    <InputSelect
                                        values={this.state.influence}
                                        options={InputFieldOptions.influence}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.influence.name] : "High"}
                                    />
                                </Col>
                                <Col span={8}>
                                    <InputSelect
                                        values={this.state.relationship}
                                        options={InputFieldOptions.relationship}
                                        value={this.props.edit && snapshot.data ? snapshot.data[this.state.relationship.name] : "Hot"}
                                    />
                                </Col>
                            </Row>
                            <Box y={10}/>
                            <Form.Item>
                                <ButtonX
                                    htmlType="submit"
                                    name="submit"
                                    text="Submit" className={"button-default-accent"}/>
                            </Form.Item>
                            <BlocBuilder
                                subject = {AuthBloc.instance.errorText}
                                builder = {(snapshotError) => {
                                    //console.log(snapshotError.data);
                                    return  <TextX text={snapshotError.data} color={Colors.water_blue}/>
                                }}/>
                        </Form> : <FormSkeleton line={2}/>
                    }}/>
            </IsLoading>
        );
    }
}

export default withRouter(ChemistCreatePage);

const config = {
    rules: [{ type: 'object', required: false, message: 'Please select date!' }],
};