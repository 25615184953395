import React from "react";
import BlocBuilder from "../../BlocBuilder";
import SettingsBloc from "../../bloc/SettingsBloc";

import AuthBloc from "../../bloc/AuthBloc";
import {Col, Form, message, Row} from "antd";
import {Colors} from "../../css/Colors";
import {TextX} from "../shared/TextX";
import {InputText} from "../shared/FormComponent/InputText";
import {Box} from "../shared/Box";
import {ButtonX} from "../shared/ButtonX";
import {InputFields} from "../../utils/InputFields";
import CrudBloc from "../../bloc/CrudBloc";
import {MenuConfig} from "../../utils/Constants";
import {getRouteList} from "../../utils/RouterUtils";
import {InputSelect} from "../shared/FormComponent/InputSelect";
import LocationBloc from "../../bloc/LocationBloc";

import {InputPassword} from "../shared/FormComponent/InputPassword";
import {IsLoading} from "../shared/IsLoading";
import {FormSkeleton} from "../shared/FormSkeleton";
import {withRouter} from "../../withRouter";
import {createResponseSub, updateResponseSub} from "../common/CreateUtils";

class UserCreatePage extends React.Component{


    formRef = React.createRef();
    constructor(props) {
        super(props);
        //

        this.state = {
            email: InputFields.email,
            name: InputFields.name,
            password: InputFields.password,
            //mobile: InputFields.mobile,
            user_group_id: InputFields.user_group_id
        };

        if(this.props.edit){
            const {id} = this.props.params;
            CrudBloc.instance.getDetails(id, MenuConfig.user);
        }else{
            CrudBloc.instance.clearDetails(MenuConfig.user.lower);
        }
        CrudBloc.instance.getList("", MenuConfig.user_group)
    }

    componentDidMount() {
        if(this.props.edit) {
            SettingsBloc.instance.setCurrentPageTitle("User Update");
            this.updateResponseSubscription = updateResponseSub(this.props.navigate,MenuConfig.user.lower, ()=>{
                message.info("Password Updated");
            });
        } else {
            SettingsBloc.instance.setCurrentPageTitle("Create new User");
            this.createResponseSubscription = createResponseSub(this.props.navigate,MenuConfig.user.lower);
        }
    }

    onFinish = (values) => {
        console.log('Success:', values);
        if(this.props.edit){
            const {id} = this.props.params;
            CrudBloc.instance.update(id, values, MenuConfig.user);
        }else{
            values.c_password = values.password;
            CrudBloc.instance.createNew(values, MenuConfig.user);
        }

    };

    componentWillUnmount() {
        this.createResponseSubscription?.unsubscribe();
        this.updateResponseSubscription?.unsubscribe();
    }

    updatePassword = (values) => {
        console.log('Success:', values);
        const {id} = this.props.params;
        CrudBloc.instance.updatePassword(id, values, MenuConfig.user.lower);

    };

    render() {
         return (
             <IsLoading name={MenuConfig.user.lower}>
                 <BlocBuilder
                     subject = {CrudBloc.instance.user}
                     builder = {(snapshot) => {
                         console.log(snapshot.data);
                         //console.log(snapshot.data ? snapshot.data[this.state.title.name] : null);
                         return   !this.props.edit ||  snapshot.data ?
                             <Row>
                                 <Col>
                                     <Form ref={this.formRef} name="control-ref" onFinish={this.onFinish} layout="vertical" className="create-form">

                                         <InputText
                                             values={this.state.email}
                                             value={this.props.edit && snapshot.data ? snapshot.data[this.state.email.name] : undefined}/>

                                         <InputText
                                             values={this.state.name}
                                             value={this.props.edit && snapshot.data ? snapshot.data[this.state.name.name] : undefined}/>
                                         { !this.props.edit &&
                                             <InputPassword
                                                 values={this.state.password}
                                                 value={this.props.edit && snapshot.data ? snapshot.data[this.state.password.name] : undefined}/> }

                                         {/*<InputText*/}
                                         {/*    values={this.state.mobile}*/}
                                         {/*    value={this.props.edit && snapshot.data ? snapshot.data[this.state.mobile.name] : undefined}/>*/}

                                         <BlocBuilder
                                             subject = {CrudBloc.instance.user_groupList}
                                             builder = {(snapshotUserGroup) => {
                                                 return  <InputSelect
                                                     values={this.state.user_group_id}
                                                     options={snapshotUserGroup.data ? snapshotUserGroup.data.list : []}
                                                     value={this.props.edit && snapshot.data ? snapshot.data[this.state.user_group_id.name] : undefined}
                                                 />
                                             }}/>



                                         <Box y={10}/>

                                         <Form.Item>
                                             <ButtonX
                                                 htmlType="submit"
                                                 name="submit"
                                                 text="Submit" className={"button-default-accent"}/>
                                         </Form.Item>

                                         <BlocBuilder
                                             subject = {AuthBloc.instance.errorText}
                                             builder = {(snapshotError) => {
                                                 //console.log(snapshotError.data);
                                                 return  <TextX text={snapshotError.data} color={Colors.water_blue}/>
                                             }}/>
                                     </Form>
                                 </Col>
                                 <Col>
                                     <Form ref={this.formRef} name="control-ref" onFinish={this.updatePassword} layout="vertical" className="create-form">

                                         <InputPassword
                                             values={this.state.password}
                                             value={this.props.edit && snapshot.data ? snapshot.data[this.state.password.name] : undefined}/>

                                         <Box y={10}/>

                                         <Form.Item>
                                             <ButtonX
                                                 htmlType="submit"
                                                 name="submit"
                                                 text="Submit" className={"button-default-accent"}/>
                                         </Form.Item>

                                         <BlocBuilder
                                             subject = {AuthBloc.instance.errorText}
                                             builder = {(snapshotError) => {
                                                 //console.log(snapshotError.data);
                                                 return  <TextX text={snapshotError.data} color={Colors.water_blue}/>
                                             }}/>
                                     </Form>
                                 </Col>
                             </Row>
                             : <FormSkeleton line={2}/>
                     }}/>
             </IsLoading>
        );
    }
}

export default withRouter(UserCreatePage);