import {zoneConstants} from "../components/zone/ZoneConstants";
import {userConstants} from "../components/user/UserConstants";
import {userGroupConstants} from "../components/user_group/UserGroupConstants";
import {healthcareProfessionalConstants} from "../components/healthcare_professional/HealthcareProfessionalConstants";
import {lineManagerConstants} from "../components/line_manager/LineManagerConstants";
import {fieldForceConstants} from "../components/field_force/FieldForceConstants";
import {designationConstants} from "../components/designation/DesignationConstants";
import {categoryConstants} from "../components/category/CategoryConstants";
import {institutionConstants} from "../components/institution/InstitutionConstants";
import {brandConstants} from "../components/brand/BrandConstants";
import {visitConstants} from "../components/visit/VisitConstants";
import {detailingConstants} from "../components/detailing/DetailingConstants";
import {samplingProductConstants} from "../components/sampling_product/SamplingProductConstants";
import {inputProductConstants} from "../components/input_product/InputProductConstants";
import {orderConstants} from "../components/order/OrderConstants";
import {reportConstants} from "../components/report/ReportConstants";
import {chemistConstants} from "../components/chemist/ChemistConstants";
import {territoryConstants} from "../components/territory/TerritoryConstants";
import {InputFields} from "./InputFields";


export const BASEURL = process.env.REACT_APP_BASE_URL;
export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
export const APP_NAME = process.env.REACT_APP_NAME;

export const SKIP = 10;
export const LIMIT = 10;

export const MenuConfig = {
    home: { lower: "dashboard", upper: "Home"},

    user: {
        lower: "user",
        upper: "User",
        api: "users",
        listTitle: "User List",
        filter: userConstants.filters,
        list: userConstants.listAttributes,
        details: userConstants.detailsAttributes,
        stream: "userList"
    },
    user_group: {
        lower: "user_group",
        upper: "UserGroup",
        api: "user-groups",
        listTitle: "UserGroup List",
        filter: userGroupConstants.filters,
        list: userGroupConstants.listAttributes,
        details: userGroupConstants.detailsAttributes,
        stream: "user_groupList"
    },

    zone: {
        lower: "zone",
        upper: "Zone",
        api: "zones",
        listTitle: "Zone List",
        filter: zoneConstants.filters,
        list: zoneConstants.listAttributes,
        details: zoneConstants.detailsAttributes,
        stream: "zoneList",
        fields: [InputFields.title, InputFields.code, InputFields.status,]
    },
    territory: {
        lower: "territory",
        upper: "Territory",
        api: "territories",
        listTitle: "Territory List",
        filter: territoryConstants.filters,
        list: territoryConstants.listAttributes,
        details: territoryConstants.detailsAttributes,
        stream: "territoryList"
    },

    chemist: {
        lower: "chemist",
        upper: "Chemist",
        api: "chemists",
        listTitle: "Chemist List",
        filter: chemistConstants.filters,
        list: chemistConstants.listAttributes,
        details: chemistConstants.detailsAttributes,
        stream: "chemistList"
    },

    healthcare_professional: {
        lower: "healthcare_professional",
        upper: "HealthcareProfessional",
        api: "healthcare-professionals",
        listTitle: "Healthcare Professional List",
        filter: healthcareProfessionalConstants.filters,
        list: healthcareProfessionalConstants.listAttributes,
        details: healthcareProfessionalConstants.detailsAttributes,
        stream: "healthcare_professionalList"
    },

    line_manager: {
        lower: "line_manager",
        upper: "LineManager",
        api: "line-managers",
        listTitle: "LineManager List",
        filter: lineManagerConstants.filters,
        list: lineManagerConstants.listAttributes,
        details: lineManagerConstants.detailsAttributes,
        stream: "line_managerList"
    },

    field_force: {
        lower: "field_force",
        upper: "FieldForce",
        api: "field-forces",
        listTitle: "FieldForce List",
        filter: fieldForceConstants.filters,
        list: fieldForceConstants.listAttributes,
        details: fieldForceConstants.detailsAttributes,
        stream: "field_forceList"
    },

    designation: {
        lower: "designation",
        upper: "Designation",
        api: "designations",
        listTitle: "Designation List",
        filter: designationConstants.filters,
        list: designationConstants.listAttributes,
        details: designationConstants.detailsAttributes,
        stream: "designationList"
    },
    category: {
        lower: "category",
        upper: "Category",
        api: "categories",
        listTitle: "Category List",
        filter: categoryConstants.filters,
        list: categoryConstants.listAttributes,
        details: categoryConstants.detailsAttributes,
        stream: "categoryList"
    },

    institution: {
        lower: "institution",
        upper: "Institution",
        api: "institutions",
        listTitle: "Institution List",
        filter: institutionConstants.filters,
        list: institutionConstants.listAttributes,
        details: institutionConstants.detailsAttributes,
        stream: "institutionList"
    },
    brand: {
        lower: "brand",
        upper: "Brand",
        api: "brands",
        listTitle: "Brand List",
        filter: brandConstants.filters,
        list: brandConstants.listAttributes,
        details: brandConstants.detailsAttributes,
        stream: "brandList",
    },

    visit: {
        lower: "visit",
        upper: "Visit",
        api: "visits",
        listTitle: "Visit List",
        filter: visitConstants.filters,
        list: visitConstants.listAttributes,
        details: visitConstants.detailsAttributes,
        stream: "visitList"
    },

    detailing: {
        lower: "detailing",
        upper: "Detailing",
        api: "detailing",
        listTitle: "Detailing List",
        filter: detailingConstants.filters,
        list: detailingConstants.listAttributes,
        details: detailingConstants.detailsAttributes,
        stream: "detailingList"
    },

    sampling_product: {
        lower: "sampling_product",
        upper: "SamplingProduct",
        api: "sampling-products",
        listTitle: "Sampling Product List",
        filter: samplingProductConstants.filters,
        list: samplingProductConstants.listAttributes,
        details: samplingProductConstants.detailsAttributes,
        stream: "sampling_productList"
    },

    input_product: {
        lower: "input_product",
        upper: "InputProduct",
        api: "input-products",
        listTitle: "Input Product List",
        filter: inputProductConstants.filters,
        list: inputProductConstants.listAttributes,
        details: inputProductConstants.detailsAttributes,
        stream: "input_productList"
    },

    order: {
        lower: "order",
        upper: "Order",
        api: "orders",
        listTitle: "Order List",
        filter: orderConstants.filters,
        list: orderConstants.listAttributes,
        details: orderConstants.detailsAttributes,
        stream: "orderList"
    },

    report: {
        lower: "report",
        upper: "Report",
        api: "reports",
        listTitle: "Report List",
        filter: reportConstants.filters,
        list: reportConstants.listAttributes,
        details: reportConstants.detailsAttributes,
        stream: "reportList"
    },
}

