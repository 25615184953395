import React from "react";
import LoadingBloc from "../../bloc/LoadingBloc";
import BlocBuilder from "../../BlocBuilder";
import {Spin} from "antd";

export class IsLoading extends React.Component {

    render() {
        const {name} = this.props;
        return <BlocBuilder
            subject={LoadingBloc.instance.isLoading}
            builder={(snapshot) => {
                return <Spin spinning={snapshot.data && snapshot.data[name] === true}> {this.props.children} </Spin>
            }}/>
    }
}