import FilterInputItems from "../shared/Filter/FilterInputItems";
import {tableContentType} from "../shared/table/table_utils";

export const visitConstants = {
  filters: [
    FilterInputItems.id,
    FilterInputItems.status
  ],
  listAttributes: [
    {
      title: 'ID',
      field: 'id',
      type: tableContentType.ID,
    },
    {
      title: 'Type',
      field: 'type',
      type: tableContentType.TEXT,
    },
    {
      title: 'Date',
      field: 'date',
      type: tableContentType.TEXT,
    },
    {
      title: 'Time',
      field: 'time',
      type: tableContentType.TEXT,
    },
    {
      title: 'Checked In',
      field: 'check_in_at',
      type: tableContentType.TEXT,
    },
    {
      title: 'Field Force',
      field: 'field_force_name',
      type: tableContentType.TEXT,
    },
    {
      title: 'Action',
      field: 'action',
      type: tableContentType.ACTION,
    },
  ],
  detailsAttributes: [
    {
      title: 'ID',
      field: 'id',
      type: tableContentType.TEXT,
    },
    {
      title: 'Type',
      field: 'type',
      type: tableContentType.TEXT,
    },
    {
      title: 'Date',
      field: 'date',
      type: tableContentType.TEXT,
    },
    {
      title: 'Time',
      field: 'time',
      type: tableContentType.TEXT,
    },
    {
      title: 'Checked In',
      field: 'check_in_at',
      type: tableContentType.DATE_TIME,
    },
    {
      title: 'Field Force',
      field: 'field_force_name',
      type: tableContentType.TEXT,
    },
    {
      title: 'Created At',
      field: 'created_at',
      type: tableContentType.DATE_TIME,
    },
  ]
}


