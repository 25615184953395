import './App.css';
import {Layout} from "antd";

import {TopMenu} from "./components/app/TopMenu/TopMenu";
import React from "react";
import NavigationMenu from "./components/app/LeftSideBar/NavigationMenu";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Protected from "./Protected";
import LoginPage from "./components/auth/LoginPage";
import MainWindow2 from "./components/app/MainWIndow2";

const { Content } = Layout;

export default function App({isAuthenticated}) {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<LoginPage/>}/>
                <Route
                    path="*"
                    element={
                        <Protected isAuthenticated={isAuthenticated}>
                            <Layout style={{height:'100vh'}}>
                                {/*<LeftSideBar location={this.props.location} history={this.props.history}/>*/}
                                <NavigationMenu/>
                                <Layout className="site-layout">
                                    <TopMenu/>
                                    <Content
                                        className="site-layout-background"
                                        style={{
                                            margin: '16px 16px 0px 16px',
                                            padding: '16px 16px 16px 16px',
                                            minHeight: 280,
                                        }}>
                                        <MainWindow2/>
                                    </Content>
                                </Layout>
                            </Layout>
                        </Protected>
                    }
                />
            </Routes>
        </BrowserRouter>
    )
}
