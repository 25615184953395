import React from "react";
import BlocBuilder from "../../BlocBuilder";
import SettingsBloc from "../../bloc/SettingsBloc";

import AuthBloc from "../../bloc/AuthBloc";
import {Form} from "antd";
import {Colors} from "../../css/Colors";
import {TextX} from "../shared/TextX";
import {InputText} from "../shared/FormComponent/InputText";
import {Box} from "../shared/Box";
import {ButtonX} from "../shared/ButtonX";
import {InputFields} from "../../utils/InputFields";
import CrudBloc from "../../bloc/CrudBloc";
import {MenuConfig} from "../../utils/Constants";
import {InputSelect} from "../shared/FormComponent/InputSelect";
import LocationBloc from "../../bloc/LocationBloc";
import {InputFieldOptions} from "../../utils/InputFieldOptions";
import {FormSkeleton} from "../shared/FormSkeleton";
import {IsLoading} from "../shared/IsLoading";
import {getCode} from "../../utils/Utils";
import {withRouter} from "../../withRouter";
import {createResponseSub, updateResponseSub} from "../common/CreateUtils";

class TerritoryCreatePage extends React.Component{


    formRef = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            title: InputFields.title,
            zone_code: InputFields.zone_code,
            code: InputFields.code,
            status: InputFields.status
        };

        if(this.props.edit){
            const {id} = this.props.params;
            CrudBloc.instance.getDetails(id, MenuConfig.territory);
        }else{
            CrudBloc.instance.clearDetails(MenuConfig.territory.lower);
        }

        CrudBloc.instance.getList({status: 1, limit: 100}, MenuConfig.zone);
    }

    componentDidMount() {
        this.selectZoneSubscription = LocationBloc.instance.selectedZone.subscribe({
            next(x) {
                if (x){
                    CrudBloc.instance.clearList(MenuConfig.territory.lower);
                    CrudBloc.instance.getList({zone_code: x, status: 1, limit: 100}, MenuConfig.territory)
                }
            },
        });
        if(this.props.edit) {
            SettingsBloc.instance.setCurrentPageTitle("Territory Update");
            this.updateResponseSubscription = updateResponseSub(this.props.navigate,MenuConfig.territory.lower)
        } else {
            SettingsBloc.instance.setCurrentPageTitle("Create Territory");
            this.createResponseSubscription = createResponseSub(this.props.navigate,MenuConfig.territory.lower);
        }
    }


    componentWillUnmount() {
        this.updateResponseSubscription?.unsubscribe()
        this.createResponseSubscription?.unsubscribe();
        this.selectZoneSubscription?.unsubscribe();
    }

    onFinish = (values) => {
        if(this.props.edit){
            const {id} = this.props.params;
            CrudBloc.instance.update(id, values, MenuConfig.territory);
        }else{
            CrudBloc.instance.createNew(values, MenuConfig.territory);
        }

    };

    render() {
        return (
            <IsLoading name={MenuConfig.territory.lower}>
                <BlocBuilder
                    subject = {CrudBloc.instance.territory}
                    builder = {(snapshot) => {
                        return   !this.props.edit ||  snapshot.data ? <Form ref={this.formRef} name="control-ref" onFinish={this.onFinish} layout="vertical" className="create-form">
                            <InputText
                                values={this.state.title}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.title.name] : undefined}/>
                            <InputText
                                values={this.state.code}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.code.name] : undefined}/>
                            <BlocBuilder
                                subject = {CrudBloc.instance.zoneList}
                                builder = {(snapshotRegion) => {
                                    return  <InputSelect
                                        onSelect={(id)=> {
                                                LocationBloc.instance.onSelectLocation(MenuConfig.zone.upper, id);
                                                //this.formRef.current?.setFieldsValue({ area_id: undefined});
                                            }
                                        }
                                        values={this.state.zone_code}
                                        value={this.props.edit && snapshot.data ?  getCode(snapshot.data, this.state.zone_code.name) : undefined}
                                        options={snapshotRegion.data ? snapshotRegion.data.list : []}
                                    />
                                }}/>

                            <InputSelect
                                values={this.state.status}
                                options={InputFieldOptions.status}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.status.name] : 1}
                            />
                            <Box y={10}/>
                            <Form.Item>
                                <ButtonX
                                    htmlType="submit"
                                    name="submit"
                                    text="Submit" className={"button-default-accent"}/>
                            </Form.Item>
                            <BlocBuilder
                                subject = {AuthBloc.instance.errorText}
                                builder = {(snapshotError) => {
                                    //console.log(snapshotError.data);
                                    return  <TextX text={snapshotError.data} color={Colors.water_blue}/>
                                }}/>
                        </Form> : <FormSkeleton line={2}/>
                    }}/>
            </IsLoading>
        );
    }
}

export default withRouter(TerritoryCreatePage);