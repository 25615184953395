import * as rxjs from "rxjs";
import {BASEURL} from "../utils/Constants";
import {apiLogin} from "../utils/RouterUtils";
import axiosWrapper from "../utils/AxiosWrapper";
import {localStorageKey} from "../utils/LocalStorage";
import LoadingBloc, {LoadingType} from "./LoadingBloc";

export default class AuthBloc{

    static instance = new AuthBloc();

    constructor(){
        this.authToken = new rxjs.BehaviorSubject(null);
        this.user = new rxjs.BehaviorSubject(null);
        this.errorText = new rxjs.BehaviorSubject(null);
    }

    setUser = (user) => {
        this.user.next(user)
    }

    setToken = (token) =>{
        this.authToken.next(token)
    }

    //the error function with deal with sending an error through the subject
    error = (error) => {
        console.log(error.data +"Error");
        if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            this.errorText.next(error.response.data.message)
        }else{
            this.errorText.next(error.data)
        }

        //this.productSubject.error(error)
    };

    onLoginSuccess = (response) => {
        LoadingBloc.instance.end(LoadingType.login);
        this.setUser(response.data.user)
        this.setToken(response.data.access_token)
        localStorage.setItem(localStorageKey.AUTH_TOKEN, response.data.access_token);
        localStorage.setItem(localStorageKey.USER, JSON.stringify(response.data.user));
    }

    login(values) {
        this.errorText.next(null)
        LoadingBloc.instance.start(LoadingType.login);
        axiosWrapper
            .post(BASEURL+ apiLogin, values)
            .then(this.onLoginSuccess)
            .catch((error) =>{
                this.error(error);
                LoadingBloc.instance.end(LoadingType.login);
            });
    }

     isLoggedIn() {
        if(localStorage.getItem(localStorageKey.AUTH_TOKEN) && localStorage.getItem(localStorageKey.USER)){
            //console.log(JSON.parse(localStorage.getItem(localStorageKey.USER)))
            AuthBloc.instance.setToken(localStorage.getItem(localStorageKey.AUTH_TOKEN));
            AuthBloc.instance.setUser(JSON.parse(localStorage.getItem(localStorageKey.USER)));
        }else{
            localStorage.clear();
            AuthBloc.instance.setToken(null);
        }
    }

    logout(){
        localStorage.clear();
        AuthBloc.instance.setToken(null);
    }

    isAdmin(){
        return this.user.value.user_group_id === 1;
    }

    isTM(){
        return this.user.value.user_group_id === 5;
    }
    isAM(){
        return this.user.value.user_group_id === 4;
    }
    isRM(){
        return this.user.value.user_group_id === 3;
    }

    isPO(){
        return this.user.value.user_group_id === 6;
    }

    isManager(){
        return this.user.value.user_group_id === 5 ||
            this.user.value.user_group_id === 3 ||
            this.user.value.user_group_id === 4 ||
            this.user.value.user_group_id === 6;
    }

    isEditor(){
        return this.user.value.user_group_id === 1 || this.user.value.user_group_id === 2;
    }

    getUser(){
        return this.user.value;
    }
}