import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import AuthBloc from "./bloc/AuthBloc";
import {AdminRoute} from "./AdminRoute";
import LoginPage from "./components/auth/LoginPage";

// const rootElement = document.getElementById("root");
// const root = ReactDOM.createRoot(rootElement);
// root.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>
// );

AuthBloc.instance.isLoggedIn();
const root = ReactDOM.createRoot(document.getElementById('root'));

AuthBloc.instance.authToken.subscribe({
    next(x) {
        root.render(
            <React.StrictMode>
               <App isAuthenticated={x}/>
            </React.StrictMode>
        );
    },
    error(err) { console.error('something wrong occurred: ' + err); },
    complete() { console.log('done'); }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
