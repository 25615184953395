import FilterInputItems from "../shared/Filter/FilterInputItems";
import {tableContentType} from "../shared/table/table_utils";

export const userConstants = {
  filters : [
    FilterInputItems.id,
    FilterInputItems.name,
    //FilterInputItems.email,
    //FilterInputItems.use,
  ],
  listAttributes : [
    {
      title: 'ID',
      field: 'id',
      type: tableContentType.ID,
    },
    {
      title: 'Name',
      field: 'name',
      type: tableContentType.TEXT,
    },
    {
      title: 'Mobile',
      field: 'mobile',
      type: tableContentType.TEXT,
    },
    // {
    //   title: 'Mobile',
    //   field: 'mobile',
    //   type: tableContentType.TEXT,
    // },
    {
      title: 'User Group',
      field: 'user_group_title',
      type: tableContentType.TEXT,
    },
    {
      title: 'Action',
      field: 'action',
      type: tableContentType.ACTION,
    },
  ],
  detailsAttributes :  [
    {
      title: 'ID',
      field: 'id',
      type: tableContentType.TEXT,
    },
    {
      title: 'Name',
      field: 'name',
      type: tableContentType.TEXT,
    },
    {
      title: 'Mobile',
      field: 'mobile',
      type: tableContentType.TEXT,
    },
    // {
    //   title: 'Mobile',
    //   field: 'mobile',
    //   type: tableContentType.TEXT,
    // },
    {
      title: 'User Group',
      field: 'user_group_title',
      type: tableContentType.TEXT,
    },
    {
      title: 'Created At',
      field: 'created_at',
      type: tableContentType.DATE_TIME,
    },
  ],
};

