import * as rxjs from "rxjs";
import {MenuConfig} from "../utils/Constants";


export default class LocationBloc {

    static instance = new LocationBloc();

    constructor(){

        for (const key of Object.keys(MenuConfig)) {
            this[`selected${MenuConfig[key].upper}`] = new rxjs.BehaviorSubject(null);
        }
    }

    onSelectLocation(name, id){
        this[`selected${name}`].next(id);
        console.log(`selected${name}: ${id}`);
    }



}