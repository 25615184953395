import logo from '../images/logo.png';
import logo_unilever from '../images/logo_unilever.png'

const Images = {
  logo: logo,
  logoUnilever: logo_unilever,
};

export default Images;



