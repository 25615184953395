import React from "react";
import {ButtonX} from "./ButtonX";
import {AppstoreAddOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

export class ButtonCreateNew extends React.Component {

    render() {
        const {to, text, width} = this.props;
        //console.log(to)
        //return AuthBloc.instance.isAdmin() ?
            return (
            <div style={{width: width ?? 120}}>
                <Link to={to}>
                    <ButtonX
                        iconRight={<AppstoreAddOutlined />}
                        name="create"
                        text={text ?? "Create New"}
                        className={"button-default-accent"}/>
                </Link>
            </div>
        )
            //: <div/>;
    }
}