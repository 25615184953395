import FilterInputItems from "../shared/Filter/FilterInputItems";
import {tableContentType} from "../shared/table/table_utils";

export const healthcareProfessionalConstants = {
  filters: [
    FilterInputItems.id,
    FilterInputItems.name,
    FilterInputItems.email,
    FilterInputItems.zone_code,
    FilterInputItems.territory_code,
    FilterInputItems.status,
  ],
  listAttributes: [
    {
      title: 'ID',
      field: 'id',
      type: tableContentType.ID,
    },
    {
      title: 'Full Name',
      field: 'full_name',
      type: tableContentType.TEXT,
    },
    {
      title: 'Mobile',
      field: 'mobile',
      type: tableContentType.TEXT,
    },
    {
      title: 'Address',
      field: 'address',
      type: tableContentType.TEXT,
    },
    {
      title: 'Chamber Name',
      field: 'chamber_name',
      type: tableContentType.TEXT,
    },
    {
      title: 'Territory',
      field: 'territory_title',
      type: tableContentType.TEXT,
    },
    {
      title: 'Territory Code',
      field: 'territory_code',
      type: tableContentType.TEXT,
    },
    {
      title: 'Zone',
      field: 'zone_title',
      type: tableContentType.TEXT,
    },
    {
      title: 'Class',
      field: 'class',
      type: tableContentType.TEXT,
    },
    {
      title: 'Status',
      field: 'status',
      type: tableContentType.STATUS,
    },
    {
      title: 'Action',
      field: 'action',
      type: tableContentType.ACTION,
    },
  ],
  detailsAttributes: [
    {
      title: 'ID',
      field: 'id',
      type: tableContentType.TEXT,
    },
    {
      title: 'Name',
      field: 'full_name',
      type: tableContentType.TEXT,
    },
    {
      title: 'Mobile',
      field: 'mobile',
      type: tableContentType.TEXT,
    },
    {
      title: 'Address',
      field: 'address',
      type: tableContentType.TEXT,
    },
    {
      title: 'Chamber Name',
      field: 'chamber_name',
      type: tableContentType.TEXT,
    },
    {
      title: 'Designation',
      field: 'designation',
      type: tableContentType.TEXT,
    },
    {
      title: 'Field Force',
      field: 'field_force_name',
      type: tableContentType.TEXT,
    },
    {
      title: 'Line Manager',
      field: 'line_manager_name',
      type: tableContentType.TEXT,
    },
    {
      title: 'Zone',
      field: 'zone_title',
      type: tableContentType.TEXT,
    },
    {
      title: 'Zone Code',
      field: 'zone_code',
      type: tableContentType.TEXT,
    },
    {
      title: 'Territory',
      field: 'territory_title',
      type: tableContentType.TEXT,
    },
    {
      title: 'Territory Code',
      field: 'territory_code',
      type: tableContentType.TEXT,
    },
    {
      title: 'Class',
      field: 'class',
      type: tableContentType.TEXT,
    },
    {
      title: 'Status',
      field: 'status',
      type: tableContentType.STATUS,
    },
    {
      title: 'Created At',
      field: 'created_at',
      type: tableContentType.DATE_TIME,
    },
  ]
}


